import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Analytics} from "@vercel/analytics/react";
import {App} from "./pages/App/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <App content={'Hello World!'}/>
        <Analytics/>
    </>
);